import axios from "axios"

let API_NAME = process.env.VUE_APP_API_NAME;

export default {
	async getStones(accessToken) {
		let res = await axios.get(API_NAME + "/stones", {
			  headers: {
				Authorization: `Bearer ${accessToken}`
			  }
			});
	  return res.data;
	},
  async saveApplication(accessToken,  formdata) {
    let res = await axios.post(API_NAME + "/web/applications",  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updatePersonalDetails(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/personal_details',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateNiscc(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/niscc',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateNmc(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/nmc',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateHcpc(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/hcpc',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateUnion(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/union',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
async saveBankDetails(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/bank_details',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveWorkExperience(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/work_experience',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveSkillsSheetAnswer(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/skills_sheets',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveHealthAnswer(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/health',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async updateHealthAnswer(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/health',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveEducation(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/education',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async deleteEducation(accessToken, application_code, member_education_id, formdata) {
    let res = await axios.delete(API_NAME + "/web/applications/" + application_code + '/education/' + member_education_id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async deleteWorkExperience(accessToken, application_code, member_work_experience_id, formdata) {
    let res = await axios.delete(API_NAME + "/web/applications/" + application_code + '/work_experience/' + member_work_experience_id,  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveNmcDetails(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/nmc_details',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  async saveAccessNi(accessToken, application_code, formdata) {
    let res = await axios.post(API_NAME + "/web/applications/" + application_code + '/access_ni',  formdata,
        {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
        
      });
      
    return res.data;
  },
  
  
}
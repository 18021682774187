<template>
    <div class="home">
          <div class="box">	
            <h4 class="title is-4">Personal Details</h4>
            
            <div class="notification is-warning">
                <p> An AccessNI check will be required as part of your registration process with Balmoral Healthcare</p>
                <p>A criminal record may not be an automatic bar to obtaining a position as an agency member</p>
                <p><a href="https://www.nidirect.gov.uk/publications/accessni-code-practice" target="_blank">Click here to view the AccessNI Code of Practice</a></p>

                </div>

                

            <form @submit.prevent="submitApplication" v-if="submitted===false">
                    <div class="field ">
                          <label class="label">Gender</label>
                          <div class="select ">
                            <select  v-model="gender_id" required class="select input" autofocus>
                                <option>Select gender</option>
                                <option v-for="row in genders" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 
                      <div class="field ">
                          <label class="label">Title</label>
                          <div class="select ">
                            <select  v-model="title" required class="select input">
                                <option>Select title</option>
                                <option v-for="row in titles" :key="row.name" :value="row.name">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 
                
                    <div class="field">
                          <label class="label">Forename</label>
                          <div class="control">
                              <input class="input" type="text" v-model="forename"  required>
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Middle Names</label>
                          <div class="control">
                              <input class="input" type="text" v-model="middlename" >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Surname</label>
                          <div class="control">
                              <input class="input" type="text" v-model="surname" required>
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Previous Surname(s)</label>
                          <div class="control">
                              <input class="input" type="text" v-model="maidenname" >
                          </div>
                      </div>

                     

                      <div class="field">
                          <label class="label">Date of Birth</label>
                          <div class="control">
                              <input class="input" type="date" v-model="date_of_birth" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">National Insurance Number</label>
                          <div class="control">
                              <input class="input" type="text" v-model="national_ins" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Nationality</label>
                          <div class="select">
                            <select  v-model="nationality_id">
                                <option>Select nationality</option>
                                <option v-for="row in nationalities" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div>

                      <div class="field ">
                          <label class="label">Are You Able To Work in The UK ?</label>
                          <div class="select ">
                            <select  v-model="work_in_uk" required class="select input" >
                                <option>Please select</option>
                                <option v-for="row in yes_no" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 

                      <div class="field">
                          <label class="label">Position Applied For</label>
                          <div class="select">
                            <select  v-model="grade_id">
                                <option>Select grade</option>
                                <option v-for="row in grades" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            <br />
                            <small>Once approved, we  can offer you shifts at various grades. Please enter your main grade here.</small>
                      </div>
                    <hr />
                      <h4 class="title is-4">Address & Contact Details</h4>

                      <div class="field">
                          <label class="label">Address</label>
                          <div class="control">
                              <input class="input" type="text" v-model="address1" required >
                          </div>
                      </div>
                      <div class="field">
                          <label class="label">County</label>
                          <div class="control">
                              <input class="input" type="text" v-model="address2" required >
                          </div>
                      </div>
                      <div class="field">
                          <label class="label">City</label>
                          <div class="control">
                              <input class="input" type="text" v-model="address3" required >
                          </div>
                      </div>
                      <div class="field">
                          <label class="label">Postcode</label>
                          <div class="control">
                              <input class="input" type="text" v-model="postcode"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Mobile Number</label>
                          <div class="control">
                              <input class="input" type="text" v-model="mobile" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Email Address</label>
                          <div class="control">
                              <input class="input" type="email" v-model="email" required >
                          </div>
                      </div>

                      <div class="notification is-success">
                        Confirmation : I grant permission for Balmoral Healthcare Agency to contact me and share my details with trusted third parties.
                      </div>

                     
                      <div class="field is-grouped">
                          <div class="control">
                              <button class="button is-success" :disabled="working===true">Start Application</button>
                          </div>
                      </div>
                  </form>

                  <div class="notification is-success content" v-if="submitted==true">
                   
                    <p>Thank you for starting your application with Balmoral Healthcare. It has now been saved and you can return to update it at any time.</p>
                    <p>We require some further information before your application can proceed. Please click below to see the various sections we need filled in now.</p>
                    <p>Once completed, press the Return to Balmoral button to let us know you're ready for us to review your application.</p>
                    </div>
            
          </div>
          
      </div>
  </template>

<script>
    //import AwardsMixin from '@/mixins/AwardsMixin.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'NewApplication',
        mixins: [],
        data: function () {
            return {
                working : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    title: '',
                    gender_id: '',
                    forename: '',
                    surname: '',
                    middlename: '',
                    maidenname: '',
                    email: '',
                    date_of_birth: '',
                    grade_id: '',
                    submitted: false,
                    grades: [],
                    genders: [],
                    nationalities: [],
                    titles : [{'name':'Mr'},{'name':'Miss'},{'name':'Mrs'},{'name':'Ms'},{'name':'Dr'},{'name':'Sister'}],
                    yes_no : [{'id': 1, 'name':'Yes'},{'id': 0, 'name':'No'}],
                    national_ins: '',
                    nationality_id: 134,
                    work_in_uk: '',
                    address1: '',
                    address2: '',
                    address3: '',
                    postcode: '',
                    mobile: ''
            }
        },
        created() {
          
              this.getGrades();
           this.getGenders();
           this.getNationalities();
        },
        methods: {
            
           
            async submitApplication() {
                this.working = true;

                var formdata = { 
                    gender_id : this.gender_id,
                    title : this.title,
                    forename : this.forename,
                    surname: this.surname,
                    middlename: this.middlename,
                    maidenname: this.maidenname,
                    email: this.email,
                    date_of_birth: this.date_of_birth,
                    grade_id : this.grade_id,
                    national_ins : this.national_ins,
                    address1 : this.address1,
                    address2 : this.address2,
                    address3 : this.address3,
                    postcode : this.postcode,
                    mobile : this.mobile,
                    work_in_uk: this.work_in_uk,
                    nationality_id: this.nationality_id
                    
                }; 

                MemberApplicationService.saveApplication(this.accessToken, formdata)
                .then((response) => {
                    //this.submitted = true
                    this.$router.push('/view/' + response.application_code)

                    this.working = false;
                    console.log(response)
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false;
                });
            },
            async getGrades() {
                UtilityService.getSimpleApiData(this.accessToken, "web/grades").then(
                    response => this.grades = response 
                );
            },
            async getGenders() {
                UtilityService.getSimpleApiData(this.accessToken, "web/genders").then(
                    response => this.genders = response 
                );
            },
            async getNationalities() {
                UtilityService.getSimpleApiData(this.accessToken, "web/nationalities").then(
                    response => this.nationalities = response 
                );
            },
           
        }
    }
    </script>
<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
              
            
           
                <h4 class="title is-4">Interview Details</h4>
                <p>Please choose an interview time from the choices below. If you cannot find something that suits, please call us.</p>

                <h3 class="title">Tuesday 19th September</h3>
                <button class="is-fullwidth button">9:00 to 10:00</button>
                <button class="is-fullwidth button">10:00 to 11:00</button>
                <button class="is-fullwidth button">11:00 to 12:00</button>

                <h3 class="title">Wednesday 20th September</h3>
                <button class="is-fullwidth button">9:00 to 10:00</button>
                <button class="is-fullwidth button">10:00 to 11:00</button>
                <button class="is-fullwidth button">11:00 to 12:00</button>
                   
                <h3 class="title">Tuesday 26th September</h3>
                <button class="is-fullwidth button">10:00 to 11:00</button>
                <button class="is-fullwidth button">11:00 to 12:00</button>

                <br />
                <button class="button is-success ">Save Interview Date</button>
           
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplicationInterview',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   access_ni_details: []
            }
        },
        created() {
          this.getAccessNiDetails()
          
        },
        methods: {
            
            async getAccessNiDetails() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/access_ni').then(
                    response => {
                        this.access_ni_details = response 
                        this.loading = false;
                    }
                );
            },
            async saveAccessNi() {
                
                this.working = true
                var formdata = { 
                    applied_for : 1,
                }; 

                
                MemberApplicationService.saveAccessNi(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    this.$emit('backToMenu')
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        }
    }
    </script>
<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
            <h4 class="title is-4">Paramedic HCPC</h4>
            
            <form @submit.prevent="updateHcpc" >
                <div class="field">
                    <label class="label">PIN Number</label>
                    <div class="control">
                        <input class="input" type="text" v-model="member.hcpc_pin_number" autofocus required>
                    </div>
                </div>

               
                
                <div class="field">
                    <label class="label">Expiry Date</label>
                    <div class="control">
                        <input class="input" type="date" v-model="member.hcpc_expiry"  >
                    </div>
                </div>

              
                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-success" :disabled="working===true">Update HCPC Details</button>
                    </div>
                </div>
            </form>
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplication',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   member: [],
                   
            }
        },
        created() {
          
          this.getApplicationHcpc()
          
        },
        methods: {
            async getApplicationHcpc() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/hcpc').then(
                    response => {
                        this.member = response 
                        this.loading = false;
                    }
                );
            },
            async updateHcpc() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    hcpc_pin_number : this.member.hcpc_pin_number,
                    hcpc_expiry : this.member.hcpc_expiry
                    
                }; 

                
                MemberApplicationService.updateHcpc (this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    this.$emit('backToMenu')
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. ")
                        }
                        else if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The information passed was not valid. Please double check and try again.")
                        }
                        else if(error.response.status === 404)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The application code passed in was not valid. Please double check and try again.")
                        }
                        else {
                            alert("Something went wrong saving your details. Please try again ot contact us and we will help you sort the issue.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        }
    }
    </script>
<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
              
            
           
                

                <div v-if="add_new==true">
                    
                    <div class="is-pulled-right"><button class="button is-small is-danger" @click="add_new=false">Cancel</button></div>
                    <h4 class="title is-4">Add New Education</h4>
                    <form  @submit.prevent="saveEducation()">
                        
                         <div class="field">
                            <label class="label">Establishment</label>
                            <div class="control">
                                <input class="input" type="text" v-model="establishment" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Course</label>
                            <div class="control">
                                <input class="input" type="text" v-model="course" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Current Year</label>
                            <div class="select">
                                <select  v-model="year" required>
                                    <option>Please select</option>
                                    <option v-for="row in years" :key="row" :value="row">{{  row  }}</option>
                                </select>
                                </div>
                                
                        </div>
                        <div class="field">
                            <label class="label">Completion Date</label>
                            <div class="control">
                                <input class="input" type="date" v-model="completion_date" required>
                            </div>
                        </div>

                        <div class="control">
                        <button class="button is-success" type="submit" @disabled="working==true">Save This Education</button>
                        </div>
                    </form>

                </div>
                <div v-else>
                    <div class="is-pulled-right"> 
                    <button class="button is-success " @click="add_new= true">Add New Education</button>
                    </div>
                    
                    <h4 class="title is-4">Education Details</h4>

                    <div class="notification is-warning" v-if="this.education.length == 0">
                        <p>Only fill in this section if you are currently working toward or have previously completed an educational degree</p>
                    </div>

                    <div v-if="education.length>0">
                        <div v-for="row in education" :key="row.id">
                            <div class="field">
                                <label class="label">Establishment</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="row.establishment">
                                </div>
                            </div>
                           
                            
                            <div class="field">
                                <label class="label">Course</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="row.course">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Current Year</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="row.year">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Completion Date</label>
                                <div class="control">
                                    <input class="input" type="date" v-model="row.completion_date" >
                                </div>
                            </div>
                            
                            <hr style="border: 1px solid #ccc">
                        </div>
                    </div>
                    

                   
                </div>
                

                
           
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplicationEducation',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   education: [],
                   add_new : false,
                   establishment:'',
                   course:'',
                   year: '',
                   completion_date: '',
                  working: false,
                  years: [1,2,3,4,5,6,7]
            }
        },
        created() {
          this.getEducation()
          
        },
        methods: {
            
            async getEducation() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/education').then(
                    response => {
                        this.education = response 
                        this.loading = false;
                    }
                );
            },
            async saveEducation() {
                
                this.working = true
                var formdata = { 
                    establishment : this.establishment,
                    course : this.course,
                    year : this.year,
                    completion_date : this.completion_date
                    
                }; 

                
                MemberApplicationService.saveEducation(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    //this.$emit('backToMenu')
                    this.getEducation();
                    this.add_new = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        }
    }
    </script>
<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
            <h4 class="title is-4">Personal Details</h4>
            
            <form @submit.prevent="updatePersonalDetails" >
                
                <div class="field ">
                          <label class="label">Gender</label>
                          <div class="select ">
                            <select  v-model="member.gender_id" required class="select input" >
                                <option>Select gender</option>
                                <option v-for="row in genders" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 
                      <div class="field ">
                          <label class="label">Title</label>
                          <div class="select ">
                            <select  v-model="member.title" required class="select input">
                                <option>Select title</option>
                                <option v-for="row in titles" :key="row.name" :value="row.name">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 
                
                    <div class="field">
                          <label class="label">Forename</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.forename"  required>
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Middle Names</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.middlename" >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Surname</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.surname" required>
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Previous Surname(s)</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.maidenname" >
                          </div>
                      </div>

                     

                      <div class="field">
                          <label class="label">Date of Birth</label>
                          <div class="control">
                              <input class="input" type="date" v-model="member.date_of_birth" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">National Insurance Number</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.national_ins" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Nationality</label>
                          <div class="select">
                            <select  v-model="member.nationality_id">
                                <option>Select nationality</option>
                                <option v-for="row in nationalities" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div>

                      <div class="field ">
                          <label class="label">Are You Able To Work in The UK ?</label>
                          <div class="select ">
                            <select  v-model="member.work_in_uk" required class="select input" >
                                <option>Please select</option>
                                <option v-for="row in yes_no" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            
                      </div> 

                      <div class="field">
                          <label class="label">Position Applied For</label>
                          <div class="select">
                            <select  v-model="member.grade_id">
                                <option>Select grade</option>
                                <option v-for="row in grades" :key="row.id" :value="row.id">{{  row.name  }}</option>
                            </select>
                            </div>
                            <br />
                            <small>Once approved, we  can offer you shifts at various grades. Please enter your main grade here.</small>
                      </div>
                    <hr />
                      <h4 class="title is-4">Address & Contact Details</h4>

                      <div class="field">
                          <label class="label">Address</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.address1" required >
                          </div>
                      </div>
                      <div class="field">
                          <label class="label">County</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.address2" required >
                          </div>
                      </div>
                      <div class="field">
                          <label class="label">City</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.address3" required >
                          </div>
                      </div>
                      <div class="field">
                          <label class="label">Postcode</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.postcode"  >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Mobile Number</label>
                          <div class="control">
                              <input class="input" type="text" v-model="member.mobile" required >
                          </div>
                      </div>

                      <div class="field">
                          <label class="label">Email Address</label>
                          <div class="control">
                              <input class="input" type="email" v-model="member.email" readinly disabled >
                          </div>
                      </div>

                
                <div class="field is-grouped">
                    <div class="control">
                        <button class="button is-success" :disabled="working===true">Update Personal Details</button>
                    </div>
                </div>
            </form>
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplication',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   member: [],
                   grades: [],
                   genders: [],
                   nationalities: [],
                   titles : [{'name':'Mr'},{'name':'Miss'},{'name':'Mrs'},{'name':'Ms'},{'name':'Dr'},{'name':'Sister'}],
                    yes_no : [{'id': 1, 'name':'Yes'},{'id': 0, 'name':'No'}],
                    
            }
        },
        created() {
            this.getGrades();
            this.getGenders();
          this.getApplicationPersonalDetails();
          this.getNationalities();
          
        },
        methods: {
            async getGrades() {
                UtilityService.getSimpleApiData(this.accessToken, "web/grades").then(
                    response => this.grades = response 
                );
            },
            async getGenders() {
                UtilityService.getSimpleApiData(this.accessToken, "web/genders").then(
                    response => this.genders = response 
                );
            },
            async getApplicationPersonalDetails() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/personal_details').then(
                    response => {
                        this.member = response 
                        this.loading = false;
                    }
                );
            },
            async getNationalities() {
                UtilityService.getSimpleApiData(this.accessToken, "web/nationalities").then(
                    response => this.nationalities = response 
                );
            },
            async updatePersonalDetails() {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    gender_id : this.member.gender_id,
                    title : this.member.title,
                    forename : this.member.forename,
                    surname: this.member.surname,
                    middlename: this.member.middlename,
                    maidenname: this.member.maidenname,
                    //email: this.member.email,
                    date_of_birth: this.member.date_of_birth,
                    grade_id : this.member.grade_id,
                    address1 : this.member.address1,
                    address2 : this.member.address2,
                    address3 : this.member.address3,
                    postcode : this.member.postcode,
                    mobile : this.member.mobile,
                    work_in_uk: this.member.work_in_uk,
                    nationality_id: this.member.nationality_id,
                    national_ins: this.member.national_ins
                    
                }; 

                
                MemberApplicationService.updatePersonalDetails(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    this.$emit('backToMenu')
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. ")
                        }
                        else if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The information passed was not valid. Please double check and try again.")
                        }
                        else if(error.response.status === 404)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The application code passed in was not valid. Please double check and try again.")
                        }
                        else {
                            alert("Something went wrong saving your details. Please try again ot contact us and we will help you sort the issue.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        }
    }
    </script>
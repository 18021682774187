<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
              
            
            
            <h4 class="title is-4">Health Questionnaire</h4>
            


            <table class="table  is-hoverable is-fullwidth" >
                <tbody>
                <template v-for=" row in health_categories " >
                    <tr :key="'title' + row.id">
                        <th colspan="2">{{ row.name}}</th>
                    </tr>
                    <tr v-for="question in row.questions " :key="'question' + question.id">
                        <td>{{ question.name}}
                            <div v-if="question.answer==1"><input class="input" type="text" :disabled="working==true" v-model="question.notes"  placeholder="Please give details ..." required @change="updateHealthAnswer(question)"/></div>
                        </td>
                        <td width="110" style="width:110px"><button class="button is-small" :class="{'is-danger' : question.answer == 1}" :disabled="working==true" @click="saveHealthAnswer(question, 1)">Yes</button>
                            <button class="button is-small " :class="{'is-success' : question.answer == 0}" :disabled="working==true"  @click="saveHealthAnswer(question, 0)">No</button></td>
                    </tr>
                    
                </template>
               
            </tbody>
            </table>

           
            
            <br />  
            <button class="button is-success" @click="checkAnswers()">I've Completed All Of The Questions</button>
            
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplication',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   health_categories: [],
                   working:false
            }
        },
        created() {
          this.getHealthCategories()
          
        },
        methods: {
            
            async getHealthCategories() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/health').then(
                    response => {
                        this.health_categories = response ;
                        this.loading = false;
                    }
                );
            },
            async saveHealthAnswer(question, value) {
                this.working = true
                var formdata = { 
                    health_question_id : question.id,
                    answer: value
                }; 

                MemberApplicationService.saveHealthAnswer(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    
                    question.answer=value
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields.")
                        }
                        this.working = false
                }); 
            },
            async updateHealthAnswer(question) {
                this.working = true
                var formdata = { 
                    _method: 'patch',
                    health_question_id : question.id,
                    notes: question.notes
                }; 

                MemberApplicationService.updateHealthAnswer(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields.")
                        }
                        this.working = false
                }); 
            },
            checkAnswers ()
            {
                if(this.allQuestionsAnsweredOne == true)
                {
                    this.$emit('backToMenu')
                }
                else{
                    alert("Please supply a Yes/No answer to all questions.")
                    return false
                }
                
            }
            
           
        },
        computed: {
            allQuestionsAnsweredOne() {
                // Loop through each category
                for (let category of this.health_categories) {
                    // Loop through questions in each category
                    for (let question of category.questions) {
                        // Check if the answer is not null
                        if (question.answer === null) {
                            return false; // If any answer is not 1, return false
                        }
                    }
                }
                return true; // If all answers are 1, return true
            }
        }
    }
    </script>
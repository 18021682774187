<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="backToOverview()">Back to Overview </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading further education ...</p>
        </div>

        
        <div class="box" v-if="loading==false">	
                <div v-if="add_new_education==true">
                    <div class="is-pulled-right"><button class="button is-small is-danger" @click="add_new_education=false" :disabled="working==true">Cancel</button></div>
                    <h4 class="title is-4">Add New Further Education Details</h4>
                    <form  @submit.prevent="saveEducation()">
                        
                        <div class="field">
                            <label class="label">Name of College/University</label>
                            <div class="control">
                                <input class="input" type="text" v-model="establishment" required>
                            </div>
                        </div>
                       
                        <div class="field">
                            <label class="label">Course</label>
                            <div class="control">
                                <input class="input" type="text" v-model="course" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Start Date</label>
                            <div class="control">
                                <input class="input" type="date" v-model="education_start_date" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">End Date</label>
                            <div class="control">
                                <input class="input" type="date" v-model="education_end_date">
                            </div>
                        </div>
                        
                        <div class="control">
                        <button class="button is-success" type="submit" :disabled="working==true">Save This Education</button>
                        </div>
                    </form>

                </div>
                <div v-else>
                    <div class="is-pulled-right"> 
                    <button class="button is-success " @click="add_new_education = true">Add New Further Education Details</button>
                    </div>
                    
                    <h4 class="title is-4">Further Education</h4>
                    <div v-if="education.length>0">
                            <table class="table is-fullwidth is-striped" >
                                <thead>
                                    <tr>
                                        <th>Name of College/University</th>
                                        <th>Course / Diploma / NVQ / Degree</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in education" :key="row.id">
                                        <td>{{ row.establishment}}</td>
                                        <td>{{ row.course }}</td>
                                        <td>{{ row.start_date_display }}</td>
                                        <td>{{ row.end_date_display }}</td>
                                        <td><button type="button" class="button is-small is-danger" @click="deleteEducation(row.id)" :disabled="working==true">Delete</button></td>
                                    </tr>
                                    </tbody>
                            </table>

                        
                    </div>
                    <div v-else>
                        <div class="notification is-warning " >
                            <p>You have not entered any further education details yet.</p>
                        </div>
                    </div>
                </div>

          </div>

          <div class="notification is-warning " v-if="loading_work_experience==true">
            <p>Loading employment ...</p>
        </div>

        <div v-if="loading_work_experience==false" class="mb-4">	
        <p>Please fill out your employment history. Please include month and year <strong>for all times you were unemployed</strong> as this is needed for our regulatory body RQIA.</p>
        <p>Acceptable examples are maternity leave, actively seeking work, looking after a relative, stay at home parent, travelling. Please note this is not an exhaustive list.</p>
            </div>

        <div class="box" v-if="loading_work_experience==false">	
                <div v-if="add_new_employment==true">
                    <div class="is-pulled-right"><button class="button is-small is-danger" @click="add_new_employment=false" :disabled="working==true">Cancel</button></div>
                    <h4 class="title is-4">Add New Employment Details</h4>
                    <form  @submit.prevent="saveWorkExperience()">
                        
                        <div class="field">
                            <label class="label">Tick this box to enter details of a period when you were not employed</label>
                            <div class="control">
                                <input class="checkbox" type="checkbox"  v-model="not_employed" >
                            </div>
                        </div>
                        
                        <div class="field">
                            <label class="label">
                                <span v-if="not_employed == false">Employer</span>
                                <span v-if="not_employed == true">Reason</span>
                            </label>
                            <div class="control">
                                <input class="input" type="text" v-model="employer_name" required>
                            </div>
                        </div>
                       
                        <div class="field" v-if="not_employed == false">
                            <label class="label">Job Title</label>
                            <div class="control">
                                <input class="input" type="text" v-model="job_title" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Start Date</label>
                            <div class="control">
                                <input class="input" type="date" v-model="work_experience_start_date" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">End Date</label>
                            <div class="control">
                                <input class="input" type="date" v-model="work_experience_end_date">
                            </div>
                        </div>
                        <div class="field" v-if="not_employed == false">
                            <label class="label">Reason For Leaving</label>
                            <div class="control">
                                <input class="input" type="text" v-model="reason_for_leaving" >
                            </div>
                        </div>
                        
                        <div class="control">
                        <button class="button is-success" type="submit" :disabled="working==true">Save This Employment</button>
                        </div>
                    </form>

                </div>
                <div v-else>
                    <div class="is-pulled-right"> 
                    <button class="button is-success " @click="add_new_employment = true">Add New Employment Details</button>
                    </div>
                    
                    <h4 class="title is-4">Employment</h4>
                    <div v-if="work_experience.length>0">
                            <table class="table is-fullwidth is-striped is-size-7" >
                                <thead>
                                    <tr>
                                        <th>College/University OR Status</th>
                                        <th>Course / Diploma / NVQ / Degree</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Reason For Leaving</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="row in work_experience" :key="row.id">
                                        <td>{{ row.employer_name}}</td>
                                        <td>{{ row.job_title }}</td>
                                        <td>{{ row.start_date_display }}</td>
                                        <td>{{ row.end_date_display }}</td>
                                        <td>{{ row.reason_for_leaving }}</td>
                                        <td><button type="button" class="button is-small is-danger" @click="deleteWorkExperience(row.id)" :disabled="working==true">Delete</button></td>
                                    </tr>
                                    </tbody>
                            </table>

                        
                    </div>
                    <div v-else>
                        <div class="notification is-warning " >
                            <p>You have not entered any employment details yet.</p>
                        </div>
                    </div>
                </div>

          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplicationWorkExperience',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:true,
                   loading_work_experience: true,
                   accessToken: '',
                   education: [],
                   add_new_education : false,
                   add_new_employment : false,
                   date_from:'',
                   date_to:'',
                   establishment: '',
                   course: '',
                   education_start_date: '',
                   education_end_date: '',
                   employer_name: '',
                   address: '',
                   telephone: '',
                   job_title: '',
                   roles_covered: '',
                   qualifications: '',
                   work_experience_start_date: '',
                   work_experience_end_date: '',
                   reason_for_leaving: '',
                   not_employed: false,
                   work_experience: [],
                   working: false
            }
        },
        created() {
          this.getWorkExperience()
          this.getEducation()
        },
        methods: {
            backToOverview() {
                if(confirm("Please make sure you have filled in your full education and employment history, detailing periods where you were also out of work as this is required for our regulatory body RQIA.")) { 
                    this.$emit('backToMenu')
                }
                else {
                    return false
                }
            },  
            async getEducation() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/education').then(
                    response => {
                        this.education = response 
                        this.loading = false;
                    }
                );
            },
            async getWorkExperience() {
                this.loading_work_experience = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/work_experience').then(
                    response => {
                        this.work_experience = response 
                        this.loading_work_experience = false;
                    }
                );
            },
            async saveEducation() {
                
                this.working = true
                var formdata = { 
                    establishment : this.establishment,
                    course : this.course,
                    start_date : this.education_start_date,
                    end_date : this.education_end_date
                    
                }; 

                MemberApplicationService.saveEducation(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    //this.$emit('backToMenu')
                    this.getEducation();
                    this.add_new_education = false

                    this.establishment = ''
                    this.course = ''
                    this.start_date = ''
                    this.end_date = ''

                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. ")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });     
            },
            async deleteEducation(member_education_id) {
                if(confirm('Are you sure you want to delete this entry ?'))
                {
                    this.working = true
                    var formdata = { 
                        active : 0,
                    }; 

                    MemberApplicationService.deleteEducation(this.accessToken, this.application_code, member_education_id, formdata)
                    .then((response) => {
                        console.log(response)
                        //this.$emit('backToMenu')
                        this.getEducation();
                        this.add_new_education = false

                        
                        this.working = false
                        
                        }).catch(() => {
                            alert("Something went wrong. Please try again or drop us an email")
                            
                            this.working = false
                    }); 
                }    
            },
            async saveWorkExperience() {
                
                this.working = true
                var formdata = { 
                    date_from : this.work_experience_start_date,
                    date_to : this.work_experience_end_date,
                    employer_name : this.employer_name,
                    job_title : this.job_title,
                    reason_for_leaving : this.reason_for_leaving,
                    not_employed: this.not_employed
                }; 

                
                MemberApplicationService.saveWorkExperience(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    //this.$emit('backToMenu')
                    this.getWorkExperience();
                    this.add_new_employment = false

                    this.employer_name = ''
                    this.job_title = ''
                    this.reason_for_leaving = ''
                    this.work_experience_start_date = ''
                    this.work_experience_end_date = ''

                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. ")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                    }); 
                

                
            },
            async deleteWorkExperience(member_work_experience_id) {
                if(confirm('Are you sure you want to delete this entry ?'))
                {
                    this.working = true
                    var formdata = { 
                        active : 0,
                    }; 

                    MemberApplicationService.deleteWorkExperience(this.accessToken, this.application_code, member_work_experience_id, formdata)
                    .then((response) => {
                        console.log(response)
                        //this.$emit('backToMenu')
                        this.getWorkExperience();
                        this.add_new_work_experience = false

                        
                        this.working = false
                        
                        }).catch(() => {
                            alert("Something went wrong. Please try again or drop us an email")
                            
                            this.working = false
                    }); 
                }    
            },
            
           
        }
    }
    </script>
<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
            <h4 class="title is-4">Equal Opportunity Monitoring Form</h4>
            <p>Belfast City Council is committed to ensuring that all eligible persons have equality of opportunity for employment and advancement in the council on the basis of ability, qualifications and aptitude for the work. To ensure the effective implementation of the Equal Opportunities Policy all applicants are requested to complete the following questionnaire. This questionnaire will be removed from your application form and will be strictly controlled in accordance with the Code of Practice on Monitoring agreed with Trade Unions.</p>
        <p><strong>This questionnaire will not be seen by either the short-listing or interview panels.</strong></p>
                <hr />

            <div class="field">
            <label class="label">Date of Birth</label>
            <div class="control">
                <input class="input" type="date" placeholder="Date of Birth" v-model="date_of_birth">
            </div>
            </div>

            <div class="field">
                <label class="label">What best describe your gender ?</label>
                <div class="control select">
                    <select class="input" placeholder="Gender" v-model="gender_option_id" @change="switchGenderView($event, $event.target.selectedIndex)">
                        <option value="">Please select ...</option>
                        <option v-for="row in gender_options" :key="row.id" :value="row.id" :other="row.other">{{  row.name  }}</option>
                        </select>
                </div>
            </div>

            <div class="field"  v-if="showGenderOther==true">
                <label class="label">Other Gender Option</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Other Gender Option" v-model="gender_option_other">
                </div>
            </div>

            <div class="field">
                <label class="label">Do you consider yourself to be trans or transgender?</label>
                <div class="control select">
                    <select class="input" placeholder="" v-model="trans_option_id" >
                        <option value="">Please select ...</option>
                        <option v-for="row in trans_options" :key="row.id" :value="row.id" >{{  row.name  }}</option>
                        </select>
                </div>
            </div>

            <p><em>*Trans can be used as an umbrella term to describe people whose gender is not the same as, nor does it sit comfortably with, the sex they were assigned at birth. Trans people may describe themselves using one or more of a wide variety of terms e.g. transgender, transsexual, gender-queer (GQ), gender-fluid, non-binary, crossdresser, genderless. The use of trans as an umbrella term may not be acceptable to all transgender people.</em></p>
            <p><em>** Someone who intends to transition, is transitioning or has transitioned from the gender they were assigned at birth.</em></p>

            

            <div class="field">
                <label class="label">Family Status</label>
                <div class="control ">
                    <select class="input" placeholder="Family Status" v-model="family_status_option_id" @change="switchFamilyStatusView($event, $event.target.selectedIndex)">
                        <option value="">Please select ...</option>
                        <option v-for="row in family_status_options" :key="row.id" :value="row.id" :other="row.other">{{  row.name  }}</option>
                        </select>
                </div>
            </div>

            <div class="field"  v-if="showFamilyStatusOther==true">
                <label class="label">Other Family Status Option</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Other Family Status Option" v-model="family_status_option_other">
                </div>
            </div>

            <div class="field">
                <label class="label">Ethnic Origin</label>
                <div class="control ">
                    <select class="input" placeholder="Family Status" v-model="ethnic_origin_option_id" @change="switchEthnicOriginView($event, $event.target.selectedIndex)">
                        <option value="">Please select ...</option>
                        <option v-for="row in ethnic_origin_options" :key="row.id" :value="row.id" :other="row.other">{{  row.name  }}</option>
                        </select>
                </div>
            </div>

            <div class="field"  v-if="showEthnicOriginOther==true">
                <label class="label">Other Ethnic Origin Option</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Other Ethnic Origin Option" v-model="ethnic_origin_option_other">
                </div>
            </div>

            <div class="field">
                <label class="label">Please state your nationality or citizenship (for example, British, Irish, Polish)</label>
                <div class="control">
                    <input class="input" type="text" placeholder="Nationality" v-model="nationality">
                </div>
            </div>
            
            <hr />
            <h3 class="title">Persons with and without a disability</h3>
            <p>A person has a disability if they have "a physical or mental impairment which has a substantial and long-term adverse effect on their ability to carry out normal day-to-day activities" (Disability Discrimination Act, 1995) </p>
            <div class="field">
                <label class="label">Do you, in accordance with the above, have a disability?</label>
                <div class="control">
                    <select class="input" v-model="have_disability">
                        <option value="">Please select ...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Prefer Not To Answer">Prefer Not To Answer</option>
                        </select>
                </div>
            </div>

            <div class="field" v-if="have_disability == 'Yes'">
                <label class="label">If yes, please state nature of disability:</label>
                <div class="control">
                    <textarea class="textarea"  v-model="nature_of_disability"></textarea>
                </div>
            </div>

            <div class="field">
                <label class="label">If No, have you ever had a disability?</label>
                <div class="control">
                    <select class="input" v-model="previous_disability">
                        <option value="">Please select ...</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                        <option value="Prefer Not To Answer">Prefer Not To Answer</option>
                        </select>
                </div>
            </div>
            
            <div class="field" v-if="previous_disability == 'Yes'">
                <p>While the selection panel will be made aware that you have a disability for the purposes of operating the Guaranteed Interview Scheme, they will not know the nature of your disability or if you need any reasonable adjustments as part of the recruitment and selection process unless you advise them.</p>
                <p>Therefore, if you require any reasonable adjustments as part of the recruitment and selection process, please outline them:</p>
                <div class="control">
                    <textarea class="textarea"  v-model="disability_history"></textarea>
                </div>
                    <br />
                <p>If you wish to discuss any of this information further or you require any further clarification about the Guaranteed Interview Scheme, please feel free to contact our Helpline on (028) 9027 0640 and we will be happy to help.</p>
                <p>In addition, if you are aware of any adjustments that you will require, should you be successful in obtaining the job, please outline them:</p>
                <div class="control">
                    <textarea class="textarea"  v-model="adjustments"></textarea>
                </div>
            </div>

            <hr />
            <h3 class="title">Persons with and without dependants</h3>

            <div class="field is-grouped">
                <div class="control">
                    <button class="button is-success" :disabled="working===true">Save</button>
                </div>
            </div>
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'EqualOpportunities',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   equal_opportunities: [],
                   gender_options: [],
                   trans_options: [],
                   family_status_options: [],
                   ethnic_origin_options: [],
                   date_of_birth: '',
                   gender_option_id: '',
                   trans_option_id: '',
                   family_status_option_id: '',
                   ethnic_origin_option_id: '',
                   showGenderOther: false,
                   showFamilyStatusOther: false,
                   showEthnicOriginOther: false,
                   nationality: '',
                   have_disability: '',
                   previous_disability: ''
            }
        },
        created() {

            this.getGenderOptions();
            this.getTransOptions();
            this.getFamilyStatusOptions();
            this.getEthnicOriginOptions();

          this.getApplicationEqualOpportunities()
          
        },
        methods: {
            switchGenderView: function(event, selectedIndex) 
            {
                var showOther = this.gender_options[selectedIndex-1].other
                if(showOther == 1)
                {
                    this.showGenderOther = true
                }
                else
                {
                    this.showGenderOther = false
                }
            },
            switchFamilyStatusView: function(event, selectedIndex) 
            {
                var showOther = this.family_status_options[selectedIndex-1].other
                if(showOther == 1)
                {
                    this.showFamilyStatusOther = true
                }
                else
                {
                    this.showFamilyStatusOther = false
                }
            },
            switchEthnicOriginView: function(event, selectedIndex) 
            {
                var showOther = this.ethnic_origin_options[selectedIndex-1].other
                if(showOther == 1)
                {
                    this.showEthnicOriginOther = true
                }
                else
                {
                    this.showEthnicOriginOther = false
                }
            },
            async getGenderOptions() {
                //this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "gender_options").then(
                    response => {
                        this.gender_options = response 
                        //this.loading = false;
                    }
                );
            },
            async getTransOptions() {
                UtilityService.getSimpleApiData(this.accessToken, "trans_options").then(
                    response => {
                        this.trans_options = response 
                    }
                );
            },
            async getFamilyStatusOptions() {
                UtilityService.getSimpleApiData(this.accessToken, "family_status_options").then(
                    response => {
                        this.family_status_options = response 
                    }
                );
            },
            async getEthnicOriginOptions() {
                UtilityService.getSimpleApiData(this.accessToken, "ethnic_origin_options").then(
                    response => {
                        this.ethnic_origin_options = response 
                    }
                );
            },
            async getApplicationEqualOpportunities() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/equal_opportunities').then(
                    response => {
                        this.equal_opportunities = response 
                        this.loading = false;
                    }
                );
            },
            async saveEqualOpportunities() {
                this.working = true
                var formdata = { 
                    bank_name : this.bank_details.bank_name,
                }; 

                
                MemberApplicationService.saveEqualOpportunities(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    this.$emit('backToMenu')
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        },
       
    }
    </script>
<template>
    <div class="home">
        <div class="is-pulled-right"><button class="button is-info is-small" @click="$emit('backToMenu')">Back Without Saving </button></div><br /><br />

        <div class="notification is-warning " v-if="loading==true">
            <p>Loading ...</p>
        </div>

        <div class="box" v-if="loading==false">	
              
            
           
                

                <div v-if="add_new==true">
                    
                    <div class="is-pulled-right"><button class="button is-small is-danger" @click="add_new=false">Cancel</button></div>
                    <h4 class="title is-4">Add New Work Experience</h4>
                    <form  @submit.prevent="saveWorkExperience()">
                        <div class="field">
                            <label class="label">Date From</label>
                            <div class="control">
                                <input class="input" type="date" v-model="date_from" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Date</label>
                            <div class="control">
                                <input class="input" type="date" v-model="date_to">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Employers Name</label>
                            <div class="control">
                                <input class="input" type="text" v-model="employer_name" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Address</label>
                            <div class="control">
                                <input class="input" type="text" v-model="address">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Telephone</label>
                            <div class="control">
                                <input class="input" type="text" v-model="telephone">
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Job Title</label>
                            <div class="control">
                                <input class="input" type="text" v-model="job_title" required>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Roles Covered</label>
                            <div class="control">
                                <textarea class="textarea" v-model="roles_covered" required></textarea>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Qualifications</label>
                            <div class="control">
                                <textarea class="textarea" v-model="qualifications"></textarea>
                            </div>
                        </div>
                        <div class="field">
                            <label class="label">Reason For Leaving</label>
                            <div class="control">
                                <textarea class="textarea" v-model="reason_for_leaving"></textarea>
                            </div>
                        </div>

                        <div class="control">
                        <button class="button is-success" type="submit" @disabled="working==true">Save This Work Experience</button>
                        </div>
                    </form>

                </div>
                <div v-else>
                    <div class="is-pulled-right"> 
                    <button class="button is-success " @click="add_new= true">Add New Work Experience</button>
                    </div>
                    
                    <h4 class="title is-4">Previous Work Experience</h4>
                    <div v-if="work_experience.length>0">
                        <div v-for="row in work_experience" :key="row.id">
                            <div class="field">
                                <label class="label">Date From</label>
                                <div class="control">
                                    <input class="input" type="date" v-model="row.date_from">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Date </label>
                                <div class="control">
                                    <input class="input" type="date" v-model="row.date_to">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Employers Name</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="row.employer_name">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Address</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="row.address">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Telephone</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="row.telephone">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Job Title</label>
                                <div class="control">
                                    <input class="input" type="text" v-model="row.job_title">
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Roles Covered</label>
                                <div class="control">
                                    <textarea class="textarea" v-model="row.roles_covered"></textarea>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Qualifications</label>
                                <div class="control">
                                    <textarea class="textarea" v-model="row.qualifications"></textarea>
                                </div>
                            </div>
                            <div class="field">
                                <label class="label">Reason For Leaving</label>
                                <div class="control">
                                    <textarea class="textarea" v-model="row.reason_for_leaving"></textarea>
                                </div>
                            </div>
                            <hr style="border: 1px solid #ccc">
                        </div>
                    </div>
                    <div v-else>
                        <div class="notification is-warning " >
                            <p>You have not entered any work experience yet.</p>
                        </div>
                    </div>

                   
                </div>
                

                
           
            
          </div>
          
      </div>
  </template>

<script>
    import UtilityService from '@/services/UtilityService.js';
    import MemberApplicationService from '@/services/MemberApplicationService.js';
    
    export default {
      name: 'ViewApplicationWorkExperience',
        mixins: [],
        props: ['application'],
        data: function () {
            return {
                   application_code : this.$route.params.id,
                   loading:false,
                   accessToken: '',
                   work_experience: [],
                   add_new : false,
                   date_from:'',
                   date_to:'',
                   employer_name: '',
                   address: '',
                   telephone: '',
                   job_title: '',
                   roles_covered: '',
                   qualifications: '',
                   reason_for_leaving: '',
                   working: false
            }
        },
        created() {
          this.getWorkExperience()
          
        },
        methods: {
            
            async getWorkExperience() {
                this.loading = true;
                UtilityService.getSimpleApiData(this.accessToken, "web/applications/" + this.application_code + '/work_experience').then(
                    response => {
                        this.work_experience = response 
                        this.loading = false;
                    }
                );
            },
            async saveWorkExperience() {
                
                this.working = true
                var formdata = { 
                    date_from : this.date_from,
                    date_to : this.date_to,
                    employer_name : this.employer_name,
                    address : this.address,
                    telephone : this.telephone,
                    job_title : this.job_title,
                    roles_covered : this.roles_covered,
                    qualifications : this.qualifications,
                    reason_for_leaving : this.reason_for_leaving
                }; 

                
                MemberApplicationService.saveWorkExperience(this.accessToken, this.application_code, formdata)
                .then((response) => {
                    console.log(response)
                    //this.$emit('backToMenu')
                    this.getWorkExperience();
                    this.add_new = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        }
                        if(error.response.status === 406)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                            alert("The barcodes passed in were not valid. Please double check and try again.")
                        }
                        
                        this.working = false
                });
                

                
            },
            
           
        }
    }
    </script>